import React from "react"
import LayoutCategory from "../../components/shared/layout/layout-category"
import {useIntl} from "gatsby-plugin-intl";

const id = "impregnating-agents";
const ImpregnatingAgents = () => {
    const intl = useIntl()
    const data = {
        key: id,
        title: intl.formatMessage({id: `seo.impregnating-agents.title`}),
        description: intl.formatMessage({id: `seo.impregnating-agents.desc`}),
    }
    return (
        <LayoutCategory data={data}></LayoutCategory>
    )
}

export default ImpregnatingAgents
